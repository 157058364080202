import Swiper, { Navigation, Scrollbar } from 'swiper';
import 'swiper/css';

const selectors = {
  carouselWrapper: '[data-collection-carousel]',
};

class CollectionCarousel extends HTMLElement {
  constructor() {
    super();

    document.addEventListener('DOMContentLoaded', () => {
      this.carouselWrappers = this.querySelectorAll(selectors.carouselWrapper);
      this.render();
    });
  }

  render() {
    // Init the carousel on load.
    this.initCarousels();

    // Subsequently, on resize, we need to check if we need a Swiper object checking the break point.
    // If not, it will get destroyed.
    this.registerEventListeners();
  }

  initCarousels() {
    this.carousel = new Swiper(selectors.carouselWrapper, {
      modules: [Navigation, Scrollbar],
      lazy: true,
      lazyPreloadPrevNext: 2,
      slidesPerView: 1.2,
      spaceBetween: 8,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.slider-scrollbar',
        draggable: true,
        hide: false,
        snapOnRelease: false,
        dragSize: 40,
      },
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          scrollbar: {
            dragSize: 140,
          },
        },
        744: {
          slidesPerView: 3.2,
          scrollbar: {
            dragSize: 140,
          },
        },
        1024: {
          slidesPerView: 4.2,
          scrollbar: {
            dragSize: 140,
          },
        },
        1280: {
          slidesPerView: 4.1,
          scrollbar: {
            dragSize: 140,
          },
        },
      },
    });
  }

  registerEventListeners() {
    window.addEventListener('resize', () => {
      this.carousel.update();
    });
  }
}

customElements.define('collection-carousel', CollectionCarousel);
